import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EventsFiltering } from '@/events/components/events-filtering';
import { EventsList } from '@/events/components/events-list';
import { EventModel } from '@/events/models';
import { AllEventsPageConnectorProps } from '@/events/pages/all-events/connector';
import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import Styles from '@/events/pages/all-events/Styles.module.scss';
import { MapView } from '@/events/components/map-view';
import { ViewModeMobileSwitch } from '@/events/components/view-mode';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { EventFilters, RegistrationStatus, ViewMode } from '@/events/reducer/types';
import { TranslationService } from '@/services/translation';
import { eventsActions } from '@/events/reducer';
import { useEventsForMapUpdater } from '@/events/hooks/use-events-for-map-updater';
import { useEventsForListUpdater } from '@/events/hooks/use-events-for-list-updater';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { useLocalStorageInitializer } from '@/events/hooks/use-local-storage-initializer';

type Props = AllEventsPageConnectorProps & {
    isLoadingAuthentication: boolean;
    isClientFilterInitialized: boolean;
};

export const AllEventsPageComponent: FunctionComponent<Props> = ({
    isLoadingAuthentication,
    authenticatedUser,
    queryParameters,
    filteredEventsByUser,
    fetchedEvents,
    isLoadingEvents,
    fetchEvents,
    fetchEventsForMap,
    isClientFilterInitialized,
}) => {
    const dispatch = useDispatch();

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const filteredEventsForMap = useSelector<RootState, EventModel[]>(
        (state) => state.events.filteredEventsForMap
    );

    useEventsForMapUpdater();
    useEventsForListUpdater();
    useLocalStorageInitializer();

    const { width } = useWindowDimensions();

    const [onlyRegisteredEvents, showOnlyRegisteredEvents] = useState<boolean>(false);
    const [selectedEventIds, selectEventsIds] = useState<string[]>([]);
    const [allowFetchingEvents, setAllowFetchingEvents] = useState<boolean>(false);

    const viewMode: ViewMode = useSelector<RootState, ViewMode>((state) => state.events.viewMode);

    const viewDetailsHandler = useCallback(
        (event: EventModel) => {
            dispatch(eventsActions.selectEvent(event));
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.VIEWING_DETAILS));

            Analytics.getInstance().fireEvent(
                AnalyticsEventName.ViewDetailsButtonClick,
                AnalyticsEventCategory.EventRegistration,
                event.name,
                {
                    event_id: event.id,
                }
            );

            window.scrollTo(0, 0);
        },
        [dispatch]
    );

    const selectedEventsHandler = useCallback(
        (newSelectedEvents: EventModel[]) => {
            selectEventsIds(newSelectedEvents.map((event) => event.id));
        },
        [selectEventsIds]
    );

    const eventsListColumnSizeForWeb = useMemo(
        () => (viewMode === ViewMode.EVENTS_LIST ? 12 : 6),
        [viewMode]
    );

    const webMainContainerClassName = useMemo(() => {
        const defaultClassNames = `ps-3 pt-3 pe-4 d-flex ${Styles.EventListLeftContainer}`;
        return viewMode === ViewMode.EVENTS_LIST
            ? `${defaultClassNames} justify-content-center`
            : `${defaultClassNames} justify-content-end`;
    }, [viewMode]);

    useEffect(() => {
        if (!isLoadingAuthentication && authenticatedUser) {
            setAllowFetchingEvents(true);
        } else if (!isLoadingAuthentication && !authenticatedUser) {
            setAllowFetchingEvents(true);
        }
    }, [isLoadingAuthentication, authenticatedUser, queryParameters]);

    useEffect(() => {
        if (isClientFilterInitialized && allowFetchingEvents) {
            fetchEvents(filters);

            if (filters.client) {
                TranslationService.getInstance().setClient([filters.client]);
            }
        }
    }, [
        filters.eventIds,
        filters.latitude,
        filters.longitude,
        filters.page,
        filters.previousPage,
        allowFetchingEvents,
        fetchEvents,
        isClientFilterInitialized,
    ]);

    useEffect(() => {
        fetchEventsForMap();
        dispatch(eventsActions.updateAllEventIds([]));
    }, [authenticatedUser, fetchEventsForMap]);

    return (
        <>
            <EventsFiltering numberOfResults={filteredEventsByUser.length} />

            <div className={`${Styles.OuterContainer} w-100`}>
                <div className={`${Styles.InnerContainer}`}>
                    {width >= 768 && (
                        <Row className={'mx-0 w-100'}>
                            <Col
                                id="events-list-container-with-map"
                                xl={eventsListColumnSizeForWeb}
                                lg={eventsListColumnSizeForWeb}
                                md={eventsListColumnSizeForWeb}
                                className={webMainContainerClassName}
                            >
                                <EventsList
                                    authenticatedUser={authenticatedUser}
                                    clients={queryParameters.clients}
                                    selectedEventIds={selectedEventIds}
                                    isLoading={isLoadingEvents || isLoadingAuthentication}
                                    onViewDetails={viewDetailsHandler}
                                    registeredEventsOnly={onlyRegisteredEvents}
                                    isMapHidden={viewMode === ViewMode.EVENTS_LIST}
                                    containerId="events-list-container-with-map"
                                />
                            </Col>

                            {viewMode === ViewMode.MAP && (
                                <Col
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    className={`${Styles.EventListRightContainer} pe-0`}
                                >
                                    <MapView
                                        mapId={'MAP_FROM_MAIN_COMPONENT'}
                                        events={filteredEventsForMap}
                                        authenticatedUser={authenticatedUser}
                                        onViewDetails={viewDetailsHandler}
                                        onSelectedEvents={selectedEventsHandler}
                                        customClassname={Styles.CustomMapView}
                                        loadingEvents={isLoadingEvents}
                                    />
                                </Col>
                            )}
                        </Row>
                    )}

                    {width < 768 && (
                        <>
                            <ViewModeMobileSwitch />

                            <Row className={'mx-0'}>
                                <Col
                                    id="events-list-container-without-map"
                                    className={`px-0 pt-2 ${Styles.EventListMobileContainer} ${viewMode !== ViewMode.EVENTS_LIST ? 'd-none' : ''}`}
                                >
                                    <EventsList
                                        authenticatedUser={authenticatedUser}
                                        clients={queryParameters.clients}
                                        selectedEventIds={selectedEventIds}
                                        isLoading={isLoadingEvents || isLoadingAuthentication}
                                        onViewDetails={viewDetailsHandler}
                                        registeredEventsOnly={onlyRegisteredEvents}
                                    />
                                </Col>

                                <Col
                                    className={`px-0 pt-2 ${viewMode !== ViewMode.MAP ? 'd-none' : ''}`}
                                >
                                    <MapView
                                        mapId={'MAP_FROM_MOBILE_COMPONENT'}
                                        events={filteredEventsForMap}
                                        authenticatedUser={authenticatedUser}
                                        onViewDetails={viewDetailsHandler}
                                        onSelectedEvents={selectedEventsHandler}
                                        customClassname={Styles.CustomMapViewMobile}
                                        loadingEvents={isLoadingEvents}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
